.e-grid .e-gridheader {
    border:2px solid green

}
.e-grid .e-headercell {
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-size: 700;
 }
        #Ukasuni {
        margin-left:40px;
        margin-bottom:90px;
        margin-top:20px;
            color: black;
            display: table;
            font-family: Georgia, serif;
            font-size: 24px;
            text-align: center;
        }

        .container {
            /* margin: 40px; */
            width: 1100px;
            height: 850px;
            display: table-cell;
            vertical-align: middle;
            background-image: url("../data/dianat.png");
            background-size: 500px;
            background-repeat: no-repeat;
            background-position: center;
        }
        /* #certificate-template {
            margin: 100px;
            width: 1100px;
            height: 850px;
            display: table-cell;
            vertical-align: middle;
            background-size: 500px;
            background-repeat: no-repeat;
            background-position: center;
        } */
        /* .pdf-content {
            width: 1800px;
            height: 1000px;
            padding: 100px;
            margin: 0 auto; 
         display: flex;
          justify-content: center;
           align-items: center; 
            
          } */
          

        


        
        .container {
    position: relative;
}
.container::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    /* Position the pseudo-element at tright side of the container */
    width: 50%;
    height: 67px;
    background-color: #0C202A;
}

.container::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    /* Position the pseudo-element at the right side of the container */
    width: 67px;
    /* Set the width of the right side element */
    height: 50%;
    /* Span the entire height of the container */
    background-color: #0C202A;
    /* Set the background color to black */
}

.section{
    position: relative;
}

.section::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    /* Position the pseudo-element at tright side of the container */
    width: 50%;
    height: 67px;
    background-color: #0C202A;
}

.section::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 15;
    /* Position the pseudo-element at the right side of the container */
    width: 67px;
    /* Set the width of the right side element */
    height: 45%;
    /* Span the entire height of the container */
    background-color: #0C202A;
    /* Set the background color to black */
    padding-top: 50%; /* Add padding to make the background color visible */
}

/* .section::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 10%; 
    height: 67px; 
    background-color: #000000;
}
.section::before {
    content: "";
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    width: 70px; 
    height: 100%; 
    background-color: #000000; 
} */





        .tophead {
            z-index: 10;
            position: absolute;
            margin: 5px;
        }



        .logo {
            color: rgb(137, 87, 21);
            margin-top: 70px;
        }

        .marquee {
            color: tan;
            font-size: 48px;
            margin: 150px 20px 20px 20px;
            display: flex;

            align-items: center;
            align-content: space-around;
            justify-content: center;
        }

        .marquee3 {
            color: rgb(0, 0, 0);
            font-size: 100px;
            margin: 20px;
            display: inline-block;
        }

        .marquee2 {
            color: rgb(0, 0, 0);
            font-size: 45px;
            margin-top: -40px;
        }

        .assignment {
            margin-top: 30px;
        }

        .assignment1 {
            margin: 0px;
            font-size: 35px;
            display: inline-block;
        }

        .assignment2 {
            margin: 0px 100px 0px 100px;
            font-size: 35px;
            
        }

        .person {
            border-bottom: 2px solid black;
            font-size: 32px;
            font-style: italic;
            padding-left:15px;
            padding-right:15px;
            margin-left:10px;
            margin-right:10px;
            display: inline-block;
        }

        .reason {
            margin: 20px;
        }

        .logo2 {
            position: absolute;
            margin-top: 170px;
            width: 200px;  
            margin-left: 50px;
        }
        .logo5{
            position: absolute;
            margin-left: 820px;
            margin-top: 130px;
         }
        .logo5 img {
           display : inline;
           margin-left : 5px;
        }
        .director .h1{
         margin-top: -50px;
         font-size: 40px;
        }

        .sinature .h1{
         margin-top: -40px;
        font-size: 40px;
        }

        .bottom {
            margin: 0px 20px 70px 70px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding: 40px;
        }
        .qrcode{
            position: absolute;
            margin: 330px 30px 10px 978px;
        }

        .logo6 {
            margin-left: 10px;
        }
        .border5{
            position: absolute;
            margin-left: 690px;
            margin-top: -280px;
        }
        .border2{
            margin-top: -310px;
            margin-left: -45px;
            position: absolute;
            transform: rotate(180deg)
        }
        .certnumber h1{
            position: absolute;
            font-size: 15px;
            margin-left: 580px;
            color: white;
            z-index: 10;
            margin-top: 22px;
        }

        .certdate h1{
            position: absolute;
            font-size: 35px;
            margin-left: 700px;
            margin-top: 120px;


        }
        a {
  text-decoration: none;
}
